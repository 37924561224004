const getters = {
    // user
    tenantId: state => state.user.tenantId,
    userInfo: state => state.user.userInfo,
    token: state => state.user.token,
    refreshToken: state => state.user.refreshToken,
    isLogin: state => state.user.isLogin,
    inviteCode: state => state.user.inviteCode,
    shareId: state => state.user.shareId,
    settingObj: state => state.user.settingObj,
    commissionData: state => state.user.commissionData,
    isSign: state => state.user.isSign,

    //mqtt
    mqttKey: state => state.mqtt.mqttKey,
    currMqttMsg: state => state.mqtt.currMqttMsg,
    reconMqttTime: state => state.mqtt.reconMqttTime,

    //other
    indexNavValue: state => state.other.indexNavValue,
    drawNavValue: state => state.other.drawNavValue,
    sysConfig: state => state.other.sysConfig,
    specificConfig: state => state.other.specificConfig, //查找未收到信息的时间
    currMoreData: state => state.other.currMoreData,
    indexMenu: state => state.other.indexMenu,
    currIndexItem: state => state.other.currIndexItem,
    imageIdList: state => state.other.imageIdList,

    //plot
    imagesMessage: state => state.plot.imagesMessage,
    imagesUrl: state => state.plot.imagesUrl,
}
export default getters
