import Vue from "vue";

import vip from "./vip.vue";

let PromptConstructor = Vue.extend(vip);
let instance;

const vipTip = function (options = {}) {
    instance = new PromptConstructor({
        data: options,
    });
    document.body.appendChild(instance.$mount().$el);
};

export default vipTip;